<template>
  <v-card flat>
    <v-card-text>
      <h2 class="mb-3">วิธีการชำระเงิน</h2>
      <hr />
<!--      <v-textarea-->
<!--        label="วิธีการชำระเงิน"-->
<!--        auto-grow-->
<!--        outlined-->
<!--        rows="1"-->
<!--        row-height="15"-->
<!--        class="mt-2 input_confirmPage"-->
<!--        placeholder="ส่งภายใน 3 วันนับจากวันชำระเงิน"-->
<!--      ></v-textarea>-->
<!--      <ckeditor :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor>-->
      <ckeditor :editor="editor"
                v-model="payment.payment_channel_detail"
                :config="editorConfig"
      ></ckeditor>




      <hr>
      <v-btn
        v-if="($store.state.permissions['all']) || ($store.state.permissions['admin_data_shop_all']) || ($store.state.permissions['admin_payments'])"
        class="mt-5"
        style="width: 100%"
        color="primary" @click="update">บันทึก</v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiAccountOutline, mdiLockOpenOutline, mdiInformationOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'

// demos
import AccountSettingsAccount from './Setting.vue'
// import AccountSettingsSecurity from './AccountSettingsSecurity.vue'
// import AccountSettingsInfo from './AccountSettingsInfo.vue'
import instance_payment from '../../../services/payment'
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Swal from "sweetalert2";


export default {
  components: {
    // backend
    // AccountSettingsAccount,
    // AccountSettingsSecurity,
    // AccountSettingsInfo,
  },
  setup() {
    return {
      // tab,
      // tabs,
      // accountSettingData,
      // icons: {
      //   mdiAccountOutline,
      //   mdiLockOpenOutline,
      //   mdiInformationOutline,
      // },
    }
  },
  data () {
    return {
      editor: ClassicEditor,
      editorConfig: {

      },
      payment : {

      }
    }
  },
  methods: {
    getPayment(){
      this.$store.state.isLoading = true
      instance_payment.showData().then(res => {
        this.payment = res.data.data
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false
      })
    },
    update() {
      this.$store.state.isLoading = true
      instance_payment.update(this.payment).then(res => {
        Swal.fire({
          icon: 'success',
          title: res.data.message,
          showConfirmButton: false,
          timer: 1500
        })
        this.payment = res.data.data
        this.$store.state.isLoading = false
      }).catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด !!',
          text: err.message,
        })
        this.$store.state.isLoading = false

      })
    },
  },
  mounted() {
    this.getPayment();
  }
}
</script>
